<template>
  <div class="modal-overlay" @click="fnCloseModal">
    <div class="modal-bottom" @click.stop>
      <div class="modal-content">
        <h3 class="modal-title">알림을 받으려면 전화번호가 꼭 필요해요!</h3>
        <p class="modal-description">
          리포트 발급이 완료되었을 경우 또는 신청한 주소에 등기 변동이 있을
          경우, 카카오톡으로 알림을 드려요
        </p>

        <input
          class="num-input"
          style="margin-bottom: 40px"
          placeholder="전화번호를 입력해주세요"
          type="tel"
          inputmode="numeric"
          pattern="[0-9]+"
          maxlength="11"
          @keypress="fnPreventNonNumeric"
          @keyup="fnFormatNumInput"
          v-model="phoneNumber"
        />

        <div
          class="enter-btn"
          v-bind:class="fnIsValidPhoneNumber ? 'enabled' : 'disabled'"
          @click="fnSubmit"
        >
          완료
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/common.css";

import { mapActions, mapState } from "vuex";

export default {
  name: "PhoneNumberPopupComponent",
  components: {},
  data() {
    return {
      phoneNumber: "",
    };
  },
  computed: {
    ...mapState("userStore", ["_mobile"]),
    fnIsValidPhoneNumber() {
      if (this.phoneNumber.length !== 11) return false;
      if (this.phoneNumber.slice(0, 3) !== "010") return false;
      return true;
    },
  },
  mounted() {},
  methods: {
    ...mapActions("userStore", ["acUpdateMobile", "acNeedMobile"]),

    fnPreventNonNumeric(event) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
    fnFormatNumInput(event) {
      const value = event.target.value.replace(/[^0-9]/g, "");
      this.phoneNumber = value;
    },
    fnFormatPhoneNumber(value) {
      const first = value.slice(0, 3);
      const second = value.slice(3, 7);
      const third = value.slice(7);
      const withDash = [first, second, third].filter((num) => num).join("-");
      return withDash.replace("010", "+82 10");
    },
    async fnSubmit() {
      await this.acUpdateMobile(this.fnFormatPhoneNumber(this.phoneNumber));
      await this.acNeedMobile();
    },
    fnCloseModal() {
      const modalBottom = this.$el.querySelector(".modal-bottom");
      modalBottom.classList.remove("slide-up");
      modalBottom.classList.add("slide-down");

      // 애니메이션 완료 후 close 이벤트 발생
      setTimeout(() => {
        this.$emit("close");
      }, 300); // 300ms = 애니메이션 시간
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 40%;
  max-width: 450px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  /* 채널톡 버튼의 z-index (10000000) 보다 높아야 함. */
  z-index: 20000000;
  right: calc(25vw - 340px);
}

.modal-bottom {
  width: 100%;
  max-width: 450px;
  background: white;
  border-radius: 20px 20px 0 0;
}

.modal-content {
  padding: 24px 20px;
}

.modal-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #121212;
  text-align: left;
  margin-bottom: 12px;
}

.modal-description {
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  line-height: 20px;
  text-align: left;
}

.num-input {
  border: none;
  outline: none;
  text-align: center;
  box-shadow: none;
  border-bottom: 2px solid var(--Grayscale-Lightgray-2, #dfdfdf);

  height: 40px;
  width: 100%;

  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.enter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 10px;
}

.enter-btn.enabled {
  background: var(--Safe-Main, #5087ff);
  color: var(--Grayscale-White, #fff);
  cursor: pointer;
}

.enter-btn.disabled {
  background: var(--Grayscale-Lightgray-2, #dfdfdf);
  color: var(--Grayscale-Gray, #979797);
  pointer-events: none;
}

/* 모달 애니메이션 */
.slide-up {
    animation: slideUp 0.3s ease-out;
}

.slide-down {
    animation: slideDown 0.3s ease-in;
}

@keyframes slideUp {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
}

@keyframes slideDown {
    from { transform: translateY(0); }
    to { transform: translateY(100%); }
}

/* 모바일 대응 */
@media screen and (max-width: 1080px) {
  .modal-overlay {
    width: 100%;
    max-width: 100%;
    right: 0;
  }

  .modal-bottom {
    max-width: 100%;
  }
}
</style>
