import store from "../index.js";
import api from "../../utils/api.js";

export const modules = {
    store,
};

export const state = {
    _nzParams: {
        nz_source: '',
        nz_medium: '',
        nz_campaign: '',
    },
    validationError: null
};

export const mutations = {
    setNzParamsDecryptData(state, item) {
        state._nzParams = {};
        state._nzParams = Object.assign({}, item);
    },
    setValidationError(state, item) {
        state.validationError = item;
    },
};

export const actions = {
    async acNzParamsDecrypt({commit}, item) {
        const url = `/api/v1/order/coupon/validate`;
        try {
            const res = await api.post(url, {nzToken: item});
            if (res?.data?.code === 0) {
                commit('setNzParamsDecryptData', res.data.data);
                return res.data;
            }
        } catch (error) {
            return false;
        }
    }
}

export const getters = {};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}