import store from "../../index.js";
import api from "../../../utils/api.js";
import OwnerHousePropertyAcConstant from '@/mixins/owner/const/ownerHousePropertyAcConstant.js';
import OwnerHousePropertyConstant from '@/mixins/owner/const/ownerHousePropertyConstant.js';

export const modules = {
    store,
};

export const state = {
    _items: {
        properties: [],
        purchased: false,
    },
    _contents: []
};

export const mutations = {  
    [OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_MORE]: (state, payload) => {
        state._items = {
            properties: [],
            purchased: false,
        };
        state._items.properties = payload?.properties || [];
        state._items.purchased = payload?.purchased || false;
    },
    [OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_ANALYZE]: (state, payload) => {
        state._contents = payload?.map(item => ({...item, toggle: false})) || [];
    },
};

export const actions = {
    async acOwnerHousePropertyMore({ commit }, params){        
        const url = OwnerHousePropertyAcConstant.GET_OWNER_HOUSE_PROPERTY_MORE;
        try {
            const res = await api.get(`${url}`, {params});
            if(res.data?.code === 0){
                commit(OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_MORE, res.data?.code === 0 ? res.data.data : {
                    properties: [],
                    purchased: false,
                });
            }
            return res.data?.code === 0 && res.data.data?.amount > 0;
        }
        catch (error) {
            return false;
        }
    },
    async acOwnerHousePropertyAnalyze({ commit }, params){        
        const url = OwnerHousePropertyAcConstant.GET_OWNER_HOUSE_PROPERTY_ANALYZE;
        try {
            const res = await api.get(`${url}`, {params});
            if(res.data?.code === 0){
                commit(OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_ANALYZE, res.data?.data);
            }
            return res.data?.code === 0 && res.data.data?.length > 0;
        }
        catch (error) {
            return false;
        }
    },
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){

        }    
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};