

//---------------------
// 집주인
//---------------------
const ownerRouter = [

    //---------------------
    // 부동산소유현황
    //---------------------
    {
        path: "/owner/house/propertie",
        name: "owner-house-propertie",
        component: () => import("../views/owner/OwnerHousePropertieMore.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    
];


export default ownerRouter;
