import store from "../../index.js";
import api from "../../../utils/api";
import UserConstant from '../../../mixins/user/const/userConstant.js';
import UserAcConstant from '../../../mixins/user/const/userAcConstant.js';

export const modules = {
    store,
};

export const state = {
    _experiment: {},
    _error:{}
};

export const mutations = {  
    [UserConstant.ADD_EXPERIMENT_INFO]: (state, payload) => {
        state._experiment = {};
        state._experiment = payload;
    },
};

export const actions = {
    async acUserExperiment({ commit }, payload) {
        const url = UserAcConstant.GET_EXPERIMENT_INFO;
        try {
            const res = await api.get(url, {params:payload});
            if(res?.data?.code === 0 && res?.data?.data?.experiment === 'present'){
                commit(UserConstant.ADD_EXPERIMENT_INFO, res?.data?.data);
                return true;
            }
            return false;
        } 
        catch(error) {
            return false;
        }
    },
};

export const getters = {
    geCodeFail: state => {
        switch(state._error.code){
            case 1100: return '등록되지 않은 코드입니다.';
            case 1101: return '등록되지 않은 코드입니다.';
            case 1102: return '유효기간이 만료된 코드입니다.';
            case 1103: return '등록할수 없는 코드입니다.';
            case 1104: return '이미 사용한 코드입니다.';
        }    
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};