

export default {

    //experiment
    GET_EXPERIMENT_INFO: '/api/v1/user-experiment',

    //invite
    GET_INVITE_INFO: '/api/v1/user-invite',
    POST_INVITE_USER_CODE_NAME: '/api/v1/user-invite-code',
    POST_INVITE_CODE: '/api/v1/user-invite',
}