<template>
    <div id="app">
        <SideArticleReview></SideArticleReview>
        <router-view class="" :key="$route.fullPath"/>
        <FooterNavigation v-show="_state.footerBool"></FooterNavigation>
        <LoadingOverlay v-if="_state.loadingBool"/>      
        <transition name="slide-fade">
            <ToasMessage v-if="_state.toastBool" />
        </transition>
        <Alert v-if="_modal.modalState" />
    </div>
</template>

<script>
  import LoadingOverlay from '@/components/common/Loading.vue'
  import ToasMessage from '@/components/common/ToastMessage.vue'
  import Alert from '@/components/common/Alert.vue'
  import FooterNavigation from '@/components/footer/navigation.vue'
  import SideArticleReview from '@/components/side-article-landing.vue'
  import { mapState, mapMutations } from 'vuex';
  import { mapActions } from 'vuex';
  import ChannelService from '@/mixins/ChannelService'
  
  export default {
      name: 'App',
      // metaInfo: {
      //     title: '내집스캔 ㅣ 전세사기, 깡통전세 예방부터 전세보증보험, 전세자금대출 가입 가능성까지 한눈에!',
      //     meta: [
      //     { vmid: "description", name: "description", content: '' },
      //     { vmid: "description2", name: "description", content: '' },
      //     ]
      // },      
      components: {
          LoadingOverlay,
          ToasMessage,
          Alert,
          FooterNavigation,
          SideArticleReview,
      },
      created() {},
      data() {
        return {}
      },
      mounted() {
        this._state.footerBool = true;
        this.acCheckToastState({type: false, msg: ''});
        ChannelService.boot();
        ChannelService.onShowMessenger(this.fnChannelTalk);
      },
      computed: {
          ...mapState(['_state', '_modal']),
          ...mapState('userStore', ['_channelTalk']),
          ...mapMutations(['setFooterBoolState']),
      },    
      methods:{
          ...mapActions([
              'acCheckToastState'
          ]),
          ...mapActions('userStore', ['acChannelTalk']),
          async fnChannelTalk(){
              const user = await this.acChannelTalk();
              if(user){
                  const { email, mobile, order_count, report_status} = this._channelTalk;
                  ChannelService.updateUserObject(email, mobile, order_count, report_status);
              }
          },
      }
  }
</script>
<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1080px){
  .slide-fade-enter {
    transform: translateX(10px);
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.1s ease;
  }

  .slide-fade-leave-to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

</style>