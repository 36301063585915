

//---------------------
// 기타
//---------------------
const etcRouter = [
    
    //---------------------
    //고액체납자
    //---------------------
    {
        path: "/tax/danger",
        name: "tax-danger",
        component: () => import("../views/tax/TaxDangerView.vue"),
    },
    {
        path: "/tax/notice",
        name: "tax-notice",
        component: () => import("../views/tax/TaxNoticeView.vue"),
    },

    //---------------------
    //김동민
    //---------------------
    {
        path: "/report/question-agreement",
        name: "report-question-agreement",
        component: () => import("../views/kimdongmin/DongminAgreement.vue")
    },
    {
        path: "/report/question",
        name: "report-question",
        component: () => import("../views/kimdongmin/DongminWrite.vue")
    },    

    //---------------------
    // 더치트
    //---------------------
    {
        path: "/cheat/financial/fraud",
        name: "the-cheat-financial-fraud",
        component: () => import("../views/the_cheat/CheckFinancialFraud.vue")
    },    

    //---------------------
    // 쿠폰
    //---------------------
    {
        path: "/coupon/check",
        name: "coupon",
        // component: () => import("../views/coupon/CouponCheck-B.vue"),
        component: () => import("../views/coupon/CouponCheck.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/coupon/check-b",
        name: "coupon-check-b",
        component: () => import("../views/coupon/CouponCheck.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/coupon/check-c",
        name: "coupon-check-c",
        component: () => import("../views/coupon/CouponCheck-C.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/coupon/check-ticket",
        name: "coupon-check-ticket",
        component: () => import("../views/coupon/CouponCheck-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },  

    //---------------------
    // 내집스캔 활용법
    //---------------------
    {
        path: "/guide-summary",
        name: "GuideSummary",
        component: () => import("../views/guide/GuideSummary.vue"),
    },

    //---------------------
    // CRM
    //---------------------
    {
        path: "/report-preview",
        name: "CRMPreview",
        component: () => import("../views/CRM/CRMPreview.vue"),
    },

    //---------------------
    //전세대출 랜딩
    //---------------------
    {
        path: "/home-loan",
        name: "HomeLoanView",
        component: () => import("../views/HomeLoanView.vue")
    },    
];


export default etcRouter;
