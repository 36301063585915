

//---------------------
//리포트
//---------------------
const reportRouter = [
    {
        path: "/report",
        name: "report-detail",
        component: () => import("../views/report-data/PreviewView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/report/view",
        name: "report-view",
        component: () => import("../views/report-data/PreviewViewAnyone.vue"),
    },
    {
        path: "/report/review",
        name: "report-review",
        component: () => import("../views/report/ReportReview.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/report/waiting",
        name: "report-waiting",
        component: () => import("../views/report/ReportWaitingView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/report/realtor",
        name: "report-realtor",
        component: () => import("../views/report-data/RealtorPreviewView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/report/loan/detail",
        name: "report-loan-detail",
        component: () => import("../components/report/LoanDetailView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/report/text",
        name: "report-text",
        component: () => import("../views/report/ReportReview.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/report/subscribe-check",
        name: "subscribe-check",
        component: () => import("../views/subscribe_change/SubscribeCheck.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/report/score",
        name: "report-score",
        component: () => import("../views/report/ReportScoreDetailView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/report/waiting/confirm-type",
        name: "report-waiting-confirm-type",
        component: () => import("../views/report/waiting/ReportWaitingType.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    
    {
        path: "/report/waiting/confirm-price",
        name: "report-waiting-confirm-price",
        component: () => import("../views/report/waiting/ReportWaitingPrice.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    
    {
        path: "/report/waiting/confirm-date",
        name: "report-waiting-confirm-date",
        component: () => import("../views/report/waiting/ReportWaitingDate.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    
    {
        path: "/report/waiting/confirm-address",
        name: "report-waiting-confirm-address",
        component: () => import("../views/report/waiting/ReportWaitingAddress.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    
    {
        path: "/report/waiting/confirm-search",
        name: "report-waiting-confirm-search",
        component: () => import("../views/report/waiting/ReportWaitingSearch.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },    
    {
        path: "/report/deposit/enter",
        name: "report-deposit-enter",
        component: () => import("../views/deposit/EnterFirstDepositView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/report/deposit/records",
        name: "report-deposit-records",
        component: () => import("../views/deposit/PastRealPriceRecordsView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },

    {
        path: "/report/owner-house/analyze",
        name: "report-owner-house-analyze",
        component: () => import("../views/report/owner/ReportOwnerHouseAnalyze.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },     
];


export default reportRouter;
