import store from "../index.js";
import api from "../../utils/api";
import UserConstant from '../../mixins/user/const/userConstant.js';

export const modules = {
  store,
};

export const state = {
    _name: " ",
    _email: " ",
    _mobile: " ",
    _channelTalk: {},
    _realtorInfo: {},
    _roleType: "",
    _code: '',
    _count: 0,
    _discount: false,
    _needMobile: false,
    _isMobileValid: false,
};

export const mutations = {  
    [UserConstant.ADD_INVITE_CODE]: (state, payload) => {

    },

    setUserName(state, item){
        state._name = item;
    },
    setUserEmail(state, item){
        state._email = item;
    },
    setUserMobile(state, item){
      state._mobile = item;
    },
    setChannelTalkInfo(state, item) {
        state._channelTalk = item
    },
    setUserCount(state, item){
      state._count = item ? Number(item) : 304452;
    },
    setWelcome(state, item) {
      state._discount = item;
    },

    setRealtorInfo(state, item) {
        state._realtorInfo = item
    },

    setUserRoleType(state, item) {
        state._roleType = item
    },
    setUserCode(state, item) {
      state._code = item
    },
    setNeedMobile(state, item) {
      state._isMobileValid = item.isMobileValid
      state._needMobile =
        !item.isMobileValid &&
        (item.reportsCount > 0 || item.subscriptionsCount > 0)
      // window.console.log(`isMobileValid: ${state._isMobileValid}, needMobile: ${state._needMobile}`)
    }
};

export const actions = {
    async acAppleStateCode() {
      try {
        const url = `/api/v1/user/login/apple/state`;
        const res = await api.post(url);

        console.log("acAppleStateCode", res.data.state);

        return res.data.state;
      } catch (error) {
        console.log("acAppleStateCode error message", error.message);
        return null;
      }
    },

    async acAppleStateCodeConfirm({ commit }, state) {
      try {
        const url = `/api/v1/user/login/apple/confirm`;
        await api.post(url, {
          state: state,
        });
        return true;
      } catch (error) {
        console.log("acAppleStateCodeConfirm error message", error.message);
        console.log(commit);
        return false;
      }
    },
    async acTerm({ commit }, body) {
      try {
        const url = `/api/v1/user-terms`;
        const res = await api.post(url, {
          ...body,
        });
        console.log("acTerm", res.data);
        return true;
      } catch (error) {
        console.log("acTerm error message", error.response.data.message);
        console.log(commit);
        return false;
      }
    },
    async acTermSearch() {
      try {
        const url = `/api/v1/user-terms`;
        const res = await api.get(url);

        console.log("acTermSearch", res.data.state);

        return res.data.state;
      } catch (error) {
        console.log("acTermSearch error message", error.response.data.message);
        return false;
      }
    },
    async acUserName({commit}){        
        try {
            const url = `/api/v1/user/name`;
            const res = await api.get(url);
            console.log('acUserName', res.data.data);
            if(res.data.code === 0){
                commit('setUserName', res.data.data.name);
                commit('setUserEmail', res.data.data.email);
                commit('setUserMobile', res.data.data.mobile);
                return true;
            }
            else{
                return false;
            }          
        }
        catch (error) {
            console.log("acUserName error message", error.message)
            return false;
        }   
    },  
    async acUserType() {
        try {
            const url = `/api/v1/user/type`;
            const res = await api.get(url);
            return res?.data?.data?.type;
        } 
        catch(error) {
            return null;
        }
    },

    async acUserRoleType({commit}) {
        try {
            const url = `/api/v1/user/role`;
            const res = await api.get(url);
            
            commit('setUserRoleType', res.data?.data?.role)
            return true
        } 
        catch(error) {
            return null;
        }
    },
    async acWelcome({ commit }) {
        try {
            const url = `/api/v1/order-discount`;
            const res = await api.get(url);
            if(res.data?.code === 0){
                commit("setWelcome", res.data?.data?.discount);
            }      
            return res.data?.data;
        } 
        catch (error) {
            return null;
        }
    },

    async acChannelTalk({commit}){ 
        try {
          const url = `/api/v1/user/info/channel-talk`;
          const res = await api.get(url);
          commit('setChannelTalkInfo', res?.data.data);
          return true;
        } 
        catch (error) {
          return null;
        }
    },  

    async acUserCount({commit}){ 
      try {
        const url = `/api/v1/user/info`;
        const res = await api.get(url);
        commit('setUserCount', res?.data.data?.count);
        return true;
      } 
      catch (error) {
        return null;
      }
  },  

    async acVerifyAuthcode({ commit }, params) {
      try {
        const url = `/api/v1/realtor/auth/code`;
        const res = await api.post(url, { ...params });

        if (res) {
          commit('setUserCode', params.auth_code)
        }
        return res;
      } catch(error) {
        return null;
      }
    },

    acRealtor({commit}, params) {
        try {
            commit('setRealtorInfo', params);
        } catch(error) {
            return null;
        }
    },

    async acNeedMobile({commit}) {
        try {
            const url = `/api/v1/user/need-mobile`;
            const res = await api.get(url);
            commit('setNeedMobile', res?.data?.data);
            return true;
        } catch(error) {
            return null;
        }
    },
    async acUpdateMobile({}, mobile) {
        try {
            const url = `/api/v1/user/mobile`;
            const res = await api.patch(url, { mobile });
            return res;
        } catch(error) {
            return null;
        }
    }
};

export const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};