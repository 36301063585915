
//---------------------
//a-b테스트
//---------------------
const abRouter = [
    {
        path: "/a-b/dateconfirm",
        name: "ab-dateconfirm",
        component: () => import("../views/a-b/DateConfirmView.vue"),
    },
    {
        path: "/a-b/prevention",
        name: "ab-prevention",
        component: () => import("../views/a-b/PreventionView.vue"),
    },    
    {
        path: "/reels-kre",
        name: "reels-kre",
        component: () => import("../views/a-b/ReelsNotionLinkView.vue"),
    },          
    {
        path: "/miso/move-a",
        name: "miso-move-n",
        component: () => import("../views/a-b/MisoMove-AView.vue"),
    },   
    {
        path: "/miso/move-b",
        name: "miso-move-b",
        component: () => import("../views/a-b/MisoMove-BView.vue"),
    },   

    // 2025.01.08
    // A가 리포트 3천원 인상안.
    // B가 기존 3천원 인상 전 버전.
    {
        path: "/analyze/amount-b",
        name: "analyze-amount-b",
        component: () => import("../views/analyze/AnalyzeAmount.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 
    {
        path: "/analyze/amount-c",
        name: "analyze-amount-c",
        component: () => import("../views/a-b/AnalyzeAmount-C.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },     

    //3회이용권
    {
        path: "/analyze/amount-ticket-b",
        name: "analyze-amount-ticket-b",
        component: () => import("../views/a-b/AnalyzeAmountTicket-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 
    {
        path: "/analyze/loan-amount-ticket-b",
        name: "loan-amount-ticket-b",
        component: () => import("../views/a-b/AnalyzeLoanAmountTicket-BView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 

    //전세대출
    {
        path: "/analyze/loan-amount",
        name: "loan-amount",
        component: () => import("../views/a-b/AnalyzeLoanAmountView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 

    // 전세대출
    {
        path: "/loan/gov/possible/analyze",
        name: "loan-gov-possible-analyze",
        component: () => import("../views/a-b/LoanPossibleGovAnalyzeView-A.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/loan/gov/possible/analyze-b",
        name: "loan-gov-possible-analyze-b",
        component: () => import("../views/a-b/LoanPossibleGovAnalyzeView-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },

    // 구독 연간 이용권
    {
        path: "/subscribe/amount-b",
        name: "subscribe-amount-b",
        component: () => import("../views/a-b/SubscribeAmount-B.vue"),
    },

    //다방랜딩
    {
        path: "/analyze/dabang",
        name: "analyze-dabang",
        component: () => import("../views/a-b/AnalyzeHome-B.vue"),
    },    
];

export default abRouter;
